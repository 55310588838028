import React from "react"
// icons from https://remixicon.com/

const TwitterFilledIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
            <path d="M20.9,7.4c0,0.2,0,0.4,0,0.6c0,6.1-4.6,13-13,13v0c-2.5,0-4.9-0.7-7-2.1c2.4,0.3,4.9-0.4,6.8-1.9c-2,0-3.7-1.3-4.3-3.2
	C4,14.1,4.7,14,5.4,13.9c-2.1-0.4-3.7-2.3-3.7-4.5V9.3c0.6,0.4,1.4,0.6,2.1,0.6c-2-1.3-2.6-4-1.4-6.1c2.3,2.9,5.8,4.6,9.4,4.8
	c-0.6-2.5,1-4.9,3.4-5.5c1.6-0.4,3.3,0.1,4.4,1.3c1-0.2,2-0.6,2.9-1.1c-0.3,1.1-1.1,2-2,2.5c0.9-0.1,1.8-0.4,2.6-0.7
	C22.5,6,21.8,6.8,20.9,7.4z"/>
        </svg>

    )
}
export default TwitterFilledIcon
