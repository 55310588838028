import React from "react"

const CSSIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 450 512" className={className}>
            <path d="M33,32l34.9,395.8L225,480l157.1-52.2L417,32H33z M346.1,112l-4.8,47.3L226,208.6l-0.3,0.1h111.5l-12.8,146.6L226.2,384
l-98.8-29.2l-6.4-73.9h48.9l3.2,38.3l52.6,13.3l54.7-15.4l3.7-61.6L117.8,255v-0.1l-0.2,0.1l-3.6-46.3L226.1,162l6.5-2.7H109.7
l-5.8-47.3H346.1z"/>
        </svg>
    )
}
export default CSSIcon
