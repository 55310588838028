import React from "react"

const HtmlIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 441 512" className={className}>
            <path d="M28.5,32l34.9,395.8L220,480l157.6-52.2L412.5,32H28.5z M336.7,159.9H152.9l4.1,49.4h175.6L319,357.7l-97.9,27v0.3H220
l-98.7-27.3l-6-75.8H163l3.5,38.1l53.5,14.5l53.7-14.5l6-62.2H112.8L100,112.2h241.1L336.7,159.9z"/>
        </svg>
    )
}
export default HtmlIcon
