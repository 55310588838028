import React from "react"

const GitIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={className}
            viewBox="0 0 18.5 18.5" >
            <path d="M17,8.6l-7-7c-0.4-0.4-1.1-0.4-1.5,0c0,0,0,0,0,0L7.1,3l1.8,1.8c0.6-0.2,1.3,0.1,1.6,0.8c0.1,0.3,0.1,0.5,0,0.8l1.8,1.8
c0.6-0.2,1.3,0.1,1.6,0.7c0.2,0.4,0,0.9-0.3,1.3c-0.5,0.5-1.3,0.5-1.7,0c-0.4-0.4-0.5-0.9-0.3-1.3L9.8,7.2v4.4
c0.6,0.3,0.9,1,0.6,1.6c-0.3,0.6-1,0.9-1.6,0.6c-0.6-0.3-0.9-1-0.6-1.6c0.1-0.3,0.4-0.5,0.6-0.6V7.2c-0.6-0.3-0.9-1-0.7-1.6
c0,0,0,0,0,0L6.4,3.7L1.6,8.5c-0.4,0.4-0.4,1.1,0,1.5c0,0,0,0,0,0l7,7c0.4,0.4,1.1,0.4,1.5,0c0,0,0,0,0,0l7-7
C17.4,9.6,17.4,9,17,8.6"/>
        </svg>

    )
}
export default GitIcon
