

function scrollToSection(sectionId) {
    const element = document.getElementById(sectionId);
    const headerOffset = 50;

    if (element == null) return

    const offset = element.getBoundingClientRect().top + window.pageYOffset - headerOffset;

    window.scrollTo({
        top: offset,
        behavior: "smooth"
    });
}

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}

export { scrollToSection, scrollToTop };