import React from "react"

const NuxtIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 36.5 32" className={className}>
            <path d="M28.6,27L28.6,27c0.1-0.1,0.1-0.2,0.1-0.2v0c0.1-0.3,0.1-0.5,0.1-0.8v0c-0.1-0.3-0.2-0.6-0.3-0.8l0,0l-7.1-12.5l-1.1-1.9
	l-8.2,14.4c-0.1,0.2-0.2,0.5-0.3,0.8v0c-0.1,0.3,0,0.7,0.1,1l0,0c0,0,0,0.1,0.1,0.1l0,0c0.2,0.4,0.7,0.8,1.7,0.8h13.2
	C27.1,27.9,28.1,27.8,28.6,27L28.6,27z M20.3,14.6L26.7,26h-13L20.3,14.6z M33.9,25.2L24.6,8.7c-0.1-0.2-0.6-1.1-1.6-1.1
	c-0.4,0-1,0.2-1.5,1l-1.2,2.1l1.1,1.9l1.7-3L32.3,26h-3.5c0,0.3,0,0.5-0.1,0.8v0c0,0.1,0,0.1-0.1,0.2v0l0,0.1
	c-0.5,0.8-1.5,0.8-1.7,0.8h5.5c0.2,0,1.2,0,1.7-0.8C34.3,26.7,34.4,26,33.9,25.2L33.9,25.2z M12,27.1c0,0,0-0.1-0.1-0.1v0
	c-0.1-0.3-0.2-0.6-0.1-1v0H4.3L15.5,6.2l3.7,6.5l1.1-1.9L17,5.2c-0.1-0.2-0.6-1-1.6-1c-0.4,0-1,0.2-1.5,1.1l-11.4,20
	c-0.1,0.2-0.6,1.1-0.1,1.9c0.2,0.4,0.7,0.8,1.7,0.8h9.5C12.7,27.9,12.2,27.4,12,27.1L12,27.1z"/>
        </svg>
    )
}
export default NuxtIcon
