import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from "react-helmet-async"

import './styles/main.scss';
import Router from './routes';
import { useInitScrollStore } from './stores';

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
  useInitScrollStore()

  return (
    <HelmetProvider>
        <Router />
    </HelmetProvider>
  )
}

root.render(<App />);
