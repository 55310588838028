import React from "react"

const JavaScriptIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 506 512" className={className}>
            <path d="M429,32H77c-26.5,0-48,21.5-48,48v352c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48V80C477,53.5,455.5,32,429,32z
 M272.8,381.4c0,43.6-25.6,63.5-62.9,63.5c-33.7,0-53.2-17.4-63.2-38.5l34.3-20.7c6.6,11.7,12.6,21.6,27.1,21.6
c13.8,0,22.6-5.4,22.6-26.5V237.7h42.1L272.8,381.4z M372.4,444.9c-39.1,0-64.4-18.6-76.7-43l34.3-19.8c9,14.7,20.8,25.6,41.5,25.6
c17.4,0,28.6-8.7,28.6-20.8c0-14.4-11.4-19.5-30.7-28l-10.5-4.5c-30.4-12.9-50.5-29.2-50.5-63.5c0-31.6,24.1-55.6,61.6-55.6
c26.8,0,46,9.3,59.8,33.7L397,290c-7.2-12.9-15-18-27.1-18c-12.3,0-20.1,7.8-20.1,18c0,12.6,7.8,17.7,25.9,25.6l10.5,4.5
c35.8,15.3,55.9,31,55.9,66.2C442.1,424.1,412.3,444.9,372.4,444.9L372.4,444.9z"/>
        </svg>
    )
}
export default JavaScriptIcon
