import React from "react"

const CloudfrontIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 35.6 35.6" className={className}>
            <path class="st0" d="M-2.2-2.2h40v40h-40V-2.2z" style={{ fill: "none"}} />
            <path class="st1" d="M25.8,24.3c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5S25.8,25.1,25.8,24.3z M26.8,24.3
    c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5C25.7,21.8,26.8,22.9,26.8,24.3L26.8,24.3z M12.8,16.3
    c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5S12.8,17.1,12.8,16.3L12.8,16.3z M13.8,16.3c0,1.4-1.1,2.5-2.5,2.5
    c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5C12.7,13.8,13.8,14.9,13.8,16.3z M17.8,8.3c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5
    s-0.7-1.5-1.5-1.5S17.8,7.4,17.8,8.3z M16.8,8.3c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5
    C17.9,10.8,16.8,9.7,16.8,8.3L16.8,8.3z M30.8,17.8c0-4.6-2.5-8.9-6.5-11.3c-0.7,0.1-1.4,0.3-2.3,0.7l-0.3-0.9
    c0.4-0.1,0.8-0.3,1.2-0.4c-1.6-0.7-3.4-1.1-5.1-1.1c-0.8,0-1.7,0.1-2.5,0.2c0.6,0.3,1.1,0.7,1.6,1.1l-0.6,0.8
    c-0.7-0.5-1.5-1-2.4-1.5c-4.9,1.6-8.4,5.8-9,11C5.9,16.1,6.9,16,8,16l0,1c-1.1,0-2.2,0.2-3.2,0.4c0,0.1,0,0.3,0,0.4
    c0,4.3,2.1,8.3,5.7,10.7c-0.6-1.9-0.9-3.6-0.9-5.4c0-1,0.2-1.8,0.3-2.6l0.1-0.6l1,0.2l-0.1,0.6c-0.2,0.8-0.3,1.6-0.3,2.4
    c0,2,0.4,4,1.3,6.2c3.1,1.6,6.7,1.9,10,0.8c0.5-1,0.9-1.9,1.2-3l1,0.3c-0.2,0.8-0.5,1.5-0.8,2.2c0.8-0.4,1.6-0.8,2.3-1.3
    c-0.2-0.4-0.4-0.9-0.6-1.3l0.9-0.4c0.2,0.3,0.3,0.7,0.5,1.1C29.1,25.2,30.8,21.6,30.8,17.8L30.8,17.8z M31.8,17.8
    c0,4.4-2,8.4-5.4,11.1c-1.2,0.9-2.6,1.7-4,2.2c-1.4,0.5-3,0.8-4.5,0.8c-2.3,0-4.6-0.6-6.6-1.7c-4.8-2.6-7.7-7.7-7.4-13.2
    C4.2,11.1,8.2,6,13.8,4.4c3.5-1.1,7.6-0.6,10.8,1.2C29,8,31.8,12.7,31.8,17.8z M16.4,10.5l-0.7-0.8c-1.1,1-2,2-3,3.6l0.8,0.5
    C14.6,12.4,15.4,11.4,16.4,10.5z M14.6,17.9c2.3,0.8,4.3,2,6.3,4l0.7-0.7c-2.1-2-4.2-3.3-6.7-4.2C14.9,17,14.6,17.9,14.6,17.9z
     M21.6,11.1c1.9,2.9,2.9,6,3.1,9.3l-1,0.1c-0.2-3.2-1.2-6.1-3-8.9C20.8,11.7,21.6,11.1,21.6,11.1z"/>
        </svg>
    )
}
export default CloudfrontIcon
