import React from 'react'
import s from './footer.module.scss'

import { Button } from 'components'

import Logo from 'assets/images/logo-white.png'

const Footer = () => {

    function openMailClient() {
        const mailtoLink = `mailto:hello@ryanmurphy.dev`;

        window.location.href = mailtoLink
    }

    return (
        <footer className={s.container}>

            <div className={s.inner}>
                <div className={s.text_container}>
                    <img src={Logo} alt='RM' className={s.logo} />

                    <h6>Email</h6>

                    <Button
                        bold
                        styleType='link'
                        functionType='click'
                        text='hello@ryanmurphy.dev'
                        color='white'
                        onClick={openMailClient}
                    />
                </div>

                <div className={s.social_container}>
                    <Button
                        styleType='button'
                        functionType='link-external'
                        icon='linkedin-filled'
                        color='filled_white'
                        destination='https://www.linkedin.com/in/ryanmurphy-dev'
                    />
                    <Button
                        styleType='button'
                        functionType='link-external'
                        icon='github-filled'
                        color='filled_white'
                        destination='https://github.com/murph406'
                    />
                    <Button
                        styleType='button'
                        functionType='link-external'
                        icon='twitter-filled'
                        color='filled_white'
                        destination='https://twitter.com/bigSkyCoder'
                    />
                </div>
            </div>


            <div className={s.bottom_container}>
                <p>
                    Made with 🤍 on Earth
                </p>
                <p>
                    Ryan Murphy | Software Engineer
                </p>
            </div>

        </footer>
    )
}

export default Footer