import React from "react"

const NodeIcon = ({ className }) => {
    return (
        <svg height="512" viewBox="0 0 512 512" width="512" className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path d="m429.76 130.07-155.43-93.22a37 37 0 0 0 -36.65 0l-155.44 93.21a38.2 38.2 0 0 0 -18.24 32.77v186.17a38.26 38.26 0 0 0 18.24 32.8l40.76 24.34.23.13c20.58 10.53 28.46 10.53 37.59 10.53 32.14 0 52.11-20.8 52.11-54.29v-180.51a8.51 8.51 0 0 0 -8.42-8.58h-22.38a8.51 8.51 0 0 0 -8.42 8.58v180.51a15 15 0 0 1 -6.85 13.07c-5.9 3.6-14.47 2.84-24.14-2.15l-39.06-23.51a1.1 1.1 0 0 1 -.48-.92v-183.54a1.32 1.32 0 0 1 .59-1.06l151.84-93a.82.82 0 0 1 .73 0l151.93 93a1.34 1.34 0 0 1 .55 1.1v183.5a1.28 1.28 0 0 1 -.45 1l-152.06 90.65a1.22 1.22 0 0 1 -.8 0l-38.83-23.06a7.8 7.8 0 0 0 -7.83-.41l-.34.2c-10.72 6.35-13.6 8-23.54 11.62-1.62.59-5.43 2-5.76 5.77s3.29 6.45 6.51 8.32l51.9 31.87a35.67 35.67 0 0 0 18.3 5.07h.58a35.87 35.87 0 0 0 17.83-5.07l155.43-93.13a38.37 38.37 0 0 0 18.24-32.83v-186.17a38.21 38.21 0 0 0 -18.24-32.76z" />
            <path d="m307.88 318.05c-37.29 0-45.24-10.42-47.6-27.24a8.43 8.43 0 0 0 -8.22-7.32h-19.8a8.44 8.44 0 0 0 -8.26 8.58c0 14.58 5.12 62.17 83.92 62.17 24.38 0 44.66-5.7 58.63-16.49s21.45-26.49 21.45-45.2c0-37.55-24.5-47.83-72.75-54.55-49.05-6.82-49.05-10.29-49.05-17.89 0-5.47 0-18.28 35.46-18.28 25.23 0 38.74 3.19 43.06 20a8.35 8.35 0 0 0 8.06 6.67h19.87a8.24 8.24 0 0 0 6.16-2.86 8.91 8.91 0 0 0 2.12-6.44c-2.57-35.55-28.56-53.58-79.24-53.58-46.06 0-73.55 20.75-73.55 55.5 0 38.1 28.49 48.87 71.29 53.33 50 5.17 50 12.71 50 19.37.03 10.38-4.28 24.23-41.55 24.23z" />
        </svg>

    )
}
export default NodeIcon
