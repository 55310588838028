import React from "react"

const VueIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 26.1 24.7" className={className}>
            <path d="M25.1,2h-9.9l-2.1,3.5L11,2H1.1l12,20.8L25.1,2z M13.1,14.4L6.2,2.6h4.4l2.4,4.2l2.4-4.2h4.4L13.1,14.4z" />
        </svg>
    )
}
export default VueIcon
