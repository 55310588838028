
const useDisableScroll = () => {

    const disableScroll = () => {
        document.body.style.overflow = "hidden";
        document.body.style.touchAction = "none";
        document.body.style.msTouchAction = "none";
    }

    const enableScroll = () => {
        document.body.style.overflow = 'visible'
        document.body.style.touchAction = "auto";
        document.body.style.msTouchAction = "auto";
    }

    return {
        disableScroll,
        enableScroll,
    }
}

export default useDisableScroll