import React from "react"

const XcodeIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 841 841" className={className}>
            <path d="M189.7,601.1c0-2,24.2-44.4,24.2-44.4c5.2-1.8,11.1-2.9,17.3-2.9c16.4,0,31.2,7.4,41.1,18.9l0,0.1c-11.7,21.2-23,39-35.4,56
l1.1-1.6c-5.4,5.5-12.9,8.9-21.3,8.9c-2.5,0-4.9-0.3-7.2-0.8l0.2,0c-12.3-2.3-21.6-13-21.6-25.8c0-3,0.5-5.9,1.5-8.6L189.7,601.1
L189.7,601.1z M103,264.4v338.7c0.6,61,49.9,110.3,110.8,110.9H232c0-2,24.2-44.4,76.6-133.1c6-12.1,14.1-24.2,22.2-38.3H183.6
c-0.5,0-1.1,0.1-1.7,0.1c-13.7,0-25.2-9.4-28.5-22l0-0.2c-0.7-2.3-1-4.9-1-7.6c0-15.9,12.9-28.7,28.7-28.7c0.2,0,0.4,0,0.6,0h0h74.6
l94.8-165.3c0,0-22.2-40.3-28.2-50.4c-2.8-4.4-4.4-9.8-4.4-15.6c0-8.1,3.2-15.4,8.5-20.7l0,0c12.1-12.1,32.2-18.1,52.4,14.1
l8.1,12.1l8.1-12.1c18.1-26.2,30.2-24.2,40.3-22.2c16.1,4,26.2,22.2,12.1,50.4l-125,209.7h42.4c14.1-24.2,28.2-48.4,42.4-74.6
c-1.7-6.5-2.7-13.8-2.7-21.5c0-3.8,0.2-7.5,0.7-11.2l-0.1,0.4c2.7-16.5,9.9-30.9,20.2-42.4l-0.1,0.1l12.1,20.1
c24.6-40.2,49.5-87.8,71.4-137.1l3.2-8.1c4-10.4,6.3-22.5,6.3-35.2c0-7.5-0.8-14.9-2.4-21.9l0.1,0.7H213.9c-0.1,0-0.2,0-0.3,0
C152.5,153.5,103,203,103,264C103,264.2,103,264.3,103,264.4L103,264.4L103,264.4z M683.6,262.4v340.7c0,0.1,0,0.2,0,0.2
c0,72.2-58.6,130.8-130.8,130.8c-0.1,0-0.2,0-0.3,0H417.5c0,0-12.1,32.2,26.2,32.2h120.9c0.1,0,0.2,0,0.3,0
c82.2,0,148.8-66.7,148.8-148.8c0-0.1,0-0.2,0-0.4v0V320.8C715.8,292.6,691.6,274.5,683.6,262.4L683.6,262.4z M221.9,734.1h-10.1
c-0.1,0-0.2,0-0.2,0c-72.2,0-130.8-58.5-130.8-130.8c0-0.1,0-0.2,0-0.3v0V264.4c0-0.1,0-0.2,0-0.2c0-72.2,58.5-130.8,130.8-130.8
c0.1,0,0.2,0,0.3,0h292.3c-13.2-9-29.5-14.4-47.1-14.4c-2.6,0-5.1,0.1-7.6,0.3l0.3,0c-4.2,0.8-9.1,1.3-14,1.3
c-10.2,0-19.9-1.9-28.8-5.5l0.5,0.2c-12.1-10.1-10.1-16.1-28.2-16.1H199.8c-0.1,0-0.2,0-0.3,0c-82.2,0-148.9,66.7-148.9,148.9
c0,0.1,0,0.2,0,0.4v0v366.9c0,1,0,2.2,0,3.4c0,77.8,61.7,141.2,138.9,143.8h0.3C209.8,762.4,215.9,746.3,221.9,734.1L221.9,734.1z
 M528.4,484.2h48.4c32.2,0,38.3,16.1,38.3,22.2c2,16.1-6,36.3-38.3,36.3h-30.2l24.2,40.3c14.1,24.2,8.1,36.3,0,44.3
c-5.7,4.7-13.1,7.6-21.2,7.6c-14.2,0-26.4-8.9-31.2-21.5l-0.1-0.2L490,564.8c-20.1,44.4-40.3,92.7-64.5,149.2h125
c61-0.5,110.3-49.9,110.9-110.8V250.3c-2-4-6.1-2-8.1,0c-47.2,67.4-89.2,144.4-122.2,226L528.4,484.2L528.4,484.2z M641.3,212
c7.7-4.1,16.8-6.6,26.5-6.6c2.8,0,5.5,0.2,8.1,0.6l-0.3,0c12.4,4.3,22.8,12,30.1,22l0.1,0.2c9.3,12.6,22.5,21.9,37.8,26.1l0.5,0.1
c8.1,2,22.2-20.1,32.2-40.3c8.4-13.8,13.6-30.4,14.1-48.2v-0.2c-9.9-8.8-22.2-15.2-35.8-18.1l-0.5-0.1c-5.7,1.5-12.2,2.4-18.9,2.4
c-2.6,0-5.1-0.1-7.6-0.4l0.3,0c-15.6-5.5-28.3-16.2-36.1-29.9l-0.2-0.3c-13.9-17.8-31.4-32.1-51.5-41.9l-0.9-0.4
c-18.7-9.1-40.3-16.1-63.1-20l-1.4-0.2c-22.5-7-48.5-10.9-75.3-10.9c-7.5,0-15,0.3-22.4,0.9l1-0.1c-20.6,1.4-39.9,5-58.2,10.5
l1.8-0.5c-2,0-6,6-2,6s18.2,2,18.2,2s-18.1,4-18.1,8.1c0,4.1,2,4,4,4s46.4-2,64.5,0c24.1,4,44.3,18.2,56.2,38l0.2,0.3
c9.7,17,15.4,37.5,15.4,59.2c0,14.9-2.7,29.2-7.6,42.4l0.3-0.9c-30.2,66.5-270.2,475.8-282.2,504c-12.1,28.2-16.1,46.4,18.1,64.5
c34.3,18.2,52.4,10.1,62.5-2C363.1,766.4,570.7,246.2,641.3,212L641.3,212z"/>
        </svg>
    )
}
export default XcodeIcon
