import React, { useMemo, useRef } from 'react'
import {  NavLink } from "react-router-dom";
import { gsap } from "gsap"
import PropTypes from 'prop-types';
import s from './button.module.scss'

import FileTextLineIcon from 'assets/icons/file-text-line.jsx'
import ExternalLinkLineIcon from 'assets/icons/external-link-line.jsx'
import TwitterFilledIcon from 'assets/icons/twitter-filled.jsx'
import GithubFilledIcon from 'assets/icons/github-filled.jsx'
import LinkedInFilledIcon from 'assets/icons/linkedin-filled'
import DownloadFileIcon from 'assets/icons/download-file'
import SendFilledIcon from 'assets/icons/send-filled'
import ArrowDownIcon from 'assets/icons/arrow-down'
import ArrowUpIcon from 'assets/icons/arrow-up'
import ArrowLeftIcon from 'assets/icons/arrow-left'
import ArrowRightIcon from 'assets/icons/arrow-right'
import HomeFilledIcon from 'assets/icons/home-filled'
import ArrowUpRight from 'assets/icons/arrow-up-right'
import MailFilled from 'assets/icons/mail-filled'

const icons = {
    'file-text-line': <FileTextLineIcon className={s.icon} />,
    'external-link-line': <ExternalLinkLineIcon className={s.icon} />,
    'twitter-filled': <TwitterFilledIcon className={s.icon} />,
    'github-filled': <GithubFilledIcon className={s.icon} />,
    'linkedin-filled': <LinkedInFilledIcon className={s.icon} />,
    'download-file': <DownloadFileIcon className={s.icon} />,
    'send-filled': <SendFilledIcon className={s.icon} />,
    'arrow-down': <ArrowDownIcon className={s.icon} />,
    'arrow-up': <ArrowUpIcon className={s.icon} />,
    'arrow-right': <ArrowRightIcon className={s.icon} />,
    'arrow-left': <ArrowLeftIcon className={s.icon} />,
    'home-filled': <HomeFilledIcon className={s.icon} />,
    'arrow-up-right': <ArrowUpRight className={s.icon} />,
    'mail-filled': <MailFilled className={s.icon} />,
}

const baseClasses = {
    'button': {
        'icon': s.icon_button,
        'text': s.button,
    },
    'link': {
        'icon': s.link,
        'text': s.link,
    }
}

const colors = [
    'green',
    'filled_green',
    'filled_white'
]

const Button = (props) => {
    const ref = useRef()
    const { onClick, text, icon, color, styleType, functionType, destination, bold } = props
    const clickAnimationDuration = 0.25


    const onClickDown = () => {
        gsap.to(ref.current, {
            scale: 0.97,
            duration: clickAnimationDuration,
        })
    }

    const onClickUp = () => {
        gsap.to(ref.current, {
            scale: 1,
            duration: clickAnimationDuration,
        })
    }

    const baseClass = useMemo(() => {
        const styleArray = [styleType]

        if (text != null) {
            styleArray.push('text')
        } else {
            styleArray.push('icon')
        }

        return baseClasses[styleArray[0]][styleArray[1]]
    }, [styleType, text])

    const colorClass = `${styleType}_${color}`
    const classNames = `${baseClass} ${s[colorClass]}  ${bold && s.bold} `;

    return (
        <>
            {functionType === 'click' && (
                <button
                    ref={ref}
                    className={classNames}
                    onClick={onClick}
                    onTouchStart={onClickDown}
                    onTouchEnd={onClickUp}
                    onMouseDown={onClickDown}
                    onMouseUp={onClickUp}
                    onMouseLeave={onClickUp}>
                    {text && (
                        <div className={s.text_container}>
                            <p>{text}</p>
                        </div>
                    )}
                    {icon && icons[icon]}
                </button>
            )}

            {functionType === 'link-external' && (
                <a className={classNames} href={destination} target='_blank' rel="noreferrer">
                    {text && (
                        <div className={s.text_container}>
                            <p>{text}</p>
                        </div>
                    )}
                    {icon && icons[icon]}
                </a>
            )}

            {functionType === 'link-internal' && (
                <NavLink className={classNames} to={destination} >
                    {text && (
                        <div className={s.text_container}>
                            <p >{text}</p>
                        </div>
                    )}
                    {icon && icons[icon]}
                </NavLink>
            )}
        </>

    )
}

export default Button


Button.propTypes = {
    styleType: PropTypes.oneOf(['button', 'link']).isRequired,
    functionType: PropTypes.oneOf(['click', 'link-external', 'link-internal']).isRequired,
    color: PropTypes.oneOf(colors).isRequired,
    icon: PropTypes.oneOf(Object.keys(icons)),
    destination: PropTypes.string
};