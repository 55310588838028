import React from "react";
import { Canvas } from '@react-three/fiber'
import s from './hero.module.scss'

import { Model, Button } from "components";
import { scrollToSection } from "utility";

const Hero = () => {
    
    const openMailClient = () => {
        const mailtoLink = `mailto:hello@ryanmurphy.dev`
        window.location.href = mailtoLink
    }

    return (
        <section className={s.container}>
            <div className={s.text_container}>
                <h1>Ryan<br /> Murphy<span className='green'>.</span></h1>

                <p>
                    Software Engineer located in the Pacific Northwest.
                </p>

                <div className={s.button_container}>
                    <Button
                        styleType='button'
                        functionType='click'
                        text='Say Hello'
                        icon='send-filled'
                        color='filled_green'
                        onClick={openMailClient}
                    />

                    <Button
                        bold
                        styleType='link'
                        functionType='click'
                        // text='My Work'
                        text='My Skills'
                        icon='arrow-up-right'
                        onClick={() => scrollToSection('skills-section')}
                    />
                </div>
            </div>

            <div className={s.modelContainer}>
                <Canvas
                    gl={{ preserveDrawingBuffer: true }}
                    dpr={[1, 1.5]}
                    shadows>
                    <Model />
                </Canvas>
            </div>
        </section>
    )
}

export default Hero