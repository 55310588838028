import React from "react"

const NextIcon = ({ className }) => {
    return (
        <svg className={className}
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" viewBox="0 0 34.2 34.2" >
            <path d="M24.9,31.1C24.7,31.2,24.7,31.2,24.9,31.1C24.9,31.1,24.9,31.1,24.9,31.1C25,31.1,25,31.1,24.9,31.1z M25.1,31
C25,31,25,31,25.1,31C25.1,31,25.2,31,25.1,31C25.2,30.9,25.2,30.9,25.1,31z M25.3,30.9C25.2,30.9,25.2,30.9,25.3,30.9
C25.3,30.9,25.3,30.9,25.3,30.9C25.3,30.8,25.3,30.8,25.3,30.9z M25.4,30.8C25.4,30.8,25.4,30.8,25.4,30.8
C25.5,30.8,25.5,30.8,25.4,30.8C25.5,30.7,25.5,30.7,25.4,30.8z M25.6,30.7C25.5,30.7,25.5,30.8,25.6,30.7c0,0,0.1-0.1,0.1-0.1
C25.7,30.6,25.7,30.6,25.6,30.7L25.6,30.7z M16.1,1.1c-0.1,0-0.3,0-0.5,0C11,1.6,6.8,4,4.1,7.8c-1.5,2.1-2.5,4.5-2.8,7
c-0.1,0.9-0.1,1.1-0.1,2.3c0,1.2,0,1.4,0.1,2.3c0.9,6,5.1,11.1,10.9,12.9c1,0.3,2.1,0.6,3.4,0.7c0.5,0.1,2.6,0.1,3.1,0
c2.2-0.2,4-0.8,5.8-1.7c0.3-0.1,0.3-0.2,0.3-0.2c-0.9-1.2-1.7-2.3-2.6-3.5l-2.6-3.5l-3.2-4.7c-1.1-1.6-2.1-3.2-3.2-4.7
c0,0,0,2.1,0,4.7c0,4.5,0,4.7-0.1,4.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2,0.1-0.7,0.1h-0.5l-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.2
l-0.1-0.1l0-6.3l0-6.3l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.7-0.1c0.6,0,0.7,0,0.9,0.2c1.3,1.9,2.6,3.9,3.9,5.8
c2.1,3.2,4.9,7.5,6.3,9.6l2.5,3.8l0.1-0.1c1.2-0.8,2.3-1.8,3.3-2.9c2-2.3,3.3-5.1,3.8-8.2c0.1-0.9,0.1-1.1,0.1-2.3
c0-1.2,0-1.4-0.1-2.3C32.1,8.8,27.8,3.7,22,1.9c-1.1-0.3-2.2-0.6-3.3-0.7C18.4,1.1,16.3,1.1,16.1,1.1L16.1,1.1z M22.6,10.8
c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1,0,1.8,0,5.7l0,5.6L22,21l-1-1.5v-4.1c0-2.6,0-4.1,0-4.2c0-0.2,0.2-0.3,0.3-0.4
c0.1-0.1,0.2-0.1,0.7-0.1C22.4,10.7,22.5,10.7,22.6,10.8L22.6,10.8z"/>
        </svg>
    )
}
export default NextIcon
