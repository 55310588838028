import { useEffect } from 'react'
import { create } from 'zustand'

import { debounce } from 'utility'

const useScrollStore = create((set) => ({
    y: 0,
    x: 0,
    lastY: 0,
    lastX: 0,
    changeY: 0,
    changeX: 0,
    setLastScroll: () => {
        set((state) => ({
            lastY: state.y,
            lastX: state.x,
        }))
    },
    setScroll: ({ x, y }) => {
        set((state) => ({
            x,
            y,
            changeY: -(state.lastY - y),
            changeX: state.lastX - x,
        }))
    }
}))

export const useInitScrollStore = () => {
    const hasInit = useScrollStore((state) => state.hasInit)
    const setScroll = useSetScroll()
    const setLastScroll = useSetLastScroll()

    useEffect(() => {
        const onScrollEnd = debounce(setLastScroll, 5)

        const setScrollPosition = () => {
            setScroll({
                x: window.scrollX,
                y: window.scrollY,
            })
        }

        const onScroll = () => {
            setScrollPosition()
            onScrollEnd()
        }

        window.addEventListener('scroll', onScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [hasInit, setScroll, setLastScroll])
}

export const useScrollY = () =>
    useScrollStore((state) => state.y)

export const useScrollX = () =>
    useScrollStore((state) => state.x)

export const useLastScrollY = () =>
    useScrollStore((state) => state.lastY)

export const useLastScrollX = () =>
    useScrollStore((state) => state.lastX)

export const useChangeScrollY = () =>
    useScrollStore((state) => state.changeY)

export const useChangeScrollX = () =>
    useScrollStore((state) => state.changeX)

export const useSetLastScroll = () =>
    useScrollStore((state) => state.setLastScroll)

export const useSetScroll = () =>
    useScrollStore((state) => state.setScroll)