import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import s from './contact.module.scss'

import { Button, SectionLabel } from 'components'
import { useIntersectionObserver } from "hooks"

const Contact = () => {
    const ref = useRef()
    const entry = useIntersectionObserver(ref, {
        threshold: 0,
        root: null,
        rootMargin: "-15%",
    });

    const animateIn = () => {
        gsap.to(ref.current, {
            opacity: 1,
            duration: 1
        })
    }

    const animateOut = () => {
        gsap.to(ref.current, {
            opacity: 0,
            duration: 1
        })
    }

    const openMailClient = () => {
        const mailtoLink = `mailto:hello@ryanmurphy.dev`
        window.location.href = mailtoLink
    }

    useEffect(() => {

        if (entry?.isIntersecting) {
            animateIn()
        }
        else {
            animateOut()
        }

    }, [entry?.isIntersecting])

    return (
        <section className={s.container}>
            <SectionLabel id='contact-section' text='Contact' />

            <div ref={ref} className={s.content_container}>
                <div className={s.heading_wrapper}>
                    <h2>Lets Connect!</h2>
                    <p className={s.text}>Whether it's for a project inquiry or a friendly chat, drop me a line via email!</p>
                </div>

                <div className={s.button_wrapper}>
                    <Button
                        styleType='button'
                        functionType='click'
                        text='Contact Me'
                        icon='mail-filled'
                        color='filled_green'
                        onClick={openMailClient}
                    />
                    <div>
                        <svg className={s.arrow}
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.24 101.73">
                            <path d="m40.92.12c-.92-.55-1.73.86-.83,1.43,7.28,4.58,15.14,9.51,19.53,17.17,3.01,5.25,5.41,15.79-4.67,14.48-4.03-.52-8.07-1.63-11.94-2.86-9.28-2.95-16.91-8.26-25.58-12.43-3.95-1.9-12.99-5.87-16.39-1.04-3.85,5.46,3.92,10.11,7.21,12.98,6.33,5.52,11.93,11.87,16.6,18.84,8.56,12.79,14.01,27.41,15.87,42.64-2.74-2.09-5.64-3.94-8.76-5.5-2.52-1.26-4.34,2.07-2.16,3.7,5.25,3.93,10.51,7.85,15.72,11.83,1.4,1.07,3.12-.42,3.22-1.85.47-6.66,2.02-13.03,4.72-19.13,1.13-2.56-2.65-4.8-3.79-2.22-2.09,4.73-3.55,9.53-4.4,14.5-2.9-24.87-14.81-48.42-33.68-65.04-1.17-1.03-2.37-2.02-3.58-3-.63-.51-1.74-1.02-2.13-1.73-.26-.47-.8-3.85-.03-4.35,2.38-1.56,9.87,2.59,11.9,3.67,6.44,3.41,12.51,7.19,19.38,9.74,7.25,2.7,27.34,10.52,28.1-3.16.75-13.47-14.41-22.79-24.29-28.67Z" />
                        </svg>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Contact