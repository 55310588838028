import React from "react"

const AwsIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 919.9 800" className={className}>
            <path d="M468.4,212.8c-21.9,89.8-20.5,85-40.3,167.1c-0.4,7.6-6.7,13.5-14.3,13.5c-0.8,0-1.7-0.1-2.5-0.2h0.1h-22.5
c-0.7,0.1-1.5,0.2-2.2,0.2c-6.8,0-12.3-5-13.2-11.5v-0.1c-19.2-61.5-45-147-62.3-204.2c-6.4-21.1-2.2-20.6,14.5-20.2
c5.8,0.2,11.4,0,17.2,0c0.4,0,0.9-0.1,1.4-0.1c6.5,0,11.9,4.8,12.8,11.1v0.1c5.6,20.2,9.4,35.6,41.5,162.7c0.6,2.5,1.4,4.9,2.2,7.2
h1.7c0.8-3.1,1.7-6.1,2.5-9.2c12.2-51.4,24.3-102.8,36.4-154.3c3.8-16,10.5-17.5,26.7-17.5h11.9c10.7,0.2,14.1,2.3,16.7,13
c9.4,36.6,36.7,159,41.7,172.5c8-28.6-2.8,12.3,44.5-170.3c3.3-12.6,6.4-15.1,19.2-15.1h19.7c8.4,0.2,10.9,2.8,8.9,11.1
c-3.8,14.9-4.5,15.5-64.5,207.6c-4.9,15.5-6.6,16.9-22.8,16.9h-16.6c-11.4,0-14.4-2-17.2-13.1C502.9,354.5,473.2,230.3,468.4,212.8
L468.4,212.8z M256.2,386.5c2.3,4.6,6.9,7.8,12.3,7.8c3.8,0,7.2-1.5,9.7-4l9.8-6.6c10.7-7,11.4-9.8,5.6-21.1
c-6.2-11.8-9.9-25.8-9.9-40.6c0-0.5,0-1,0-1.5v0.1c0-4.9,0.9-87-1.4-104.3c-2.1-29-22.2-52.8-49-60.4l-0.5-0.1
c-11.8-3.3-25.3-5.1-39.2-5.1c-4.2,0-8.4,0.2-12.5,0.5l0.5,0c-24.5,1-47.3,7.5-67.5,18.2l0.8-0.4c-3,1.6-5.3,4.3-6.4,7.6l0,0.1
c-0.9,3.9-1.4,8.5-1.4,13.1c0,2,0.1,4.1,0.3,6.1l0-0.3c0.9,9.2,4.1,10.9,12.8,8c8-2.7,15.6-6.1,23.6-8.4c11.5-4.3,24.8-6.7,38.6-6.7
c10.8,0,21.2,1.5,31,4.3l-0.8-0.2c10.6,3.2,18.8,11.1,22.3,21.3l0.1,0.2c2.6,8.6,4.1,18.5,4.1,28.7c0,3.1-0.1,6.3-0.4,9.3l0-0.4
c0,8.6-0.2,8.6-8.6,7c-14.3-3.6-30.8-5.6-47.7-5.6c-6.2,0-12.4,0.3-18.5,0.8l0.8-0.1c-24.2,1.8-45.3,13.5-59.6,31.1l-0.1,0.2
c-8.7,12-13.9,27-13.9,43.3c0,6.6,0.8,12.9,2.4,19l-0.1-0.5c3.5,22.7,19.6,40.9,40.8,47.4l0.4,0.1c9.3,3.3,20.1,5.3,31.3,5.3
c22.2,0,42.7-7.6,59-20.3l-0.2,0.2c5.9-4.4,11.3-9.7,17.3-14.9c4.9,7.8,9.1,15.1,13.9,21.9L256.2,386.5z M232.2,290.2h0.2
c3.7,0,6.7,3,6.7,6.7c0,0.5,0,0.9-0.1,1.4v0c-0.3,5.9,0.2,11.9-0.5,17.8c-1.7,17.4-12.6,32-27.7,38.8l-0.3,0.1
c-9.8,5.1-21.4,8.2-33.7,8.2c-2.5,0-5-0.1-7.4-0.4l0.3,0c-17.6-1-31.5-15.6-31.5-33.4c0-2.7,0.3-5.4,1-8l0,0.2
c0.9-18.3,15.3-32.9,33.5-34h0.1c7-1.1,15.1-1.8,23.3-1.8c12.9,0,25.5,1.6,37.5,4.6l-1.1-0.2L232.2,290.2z M777.1,391.9
c27.5-9.2,46.9-34.8,46.9-64.8c0-4.7-0.5-9.3-1.4-13.7l0.1,0.4c-3.6-21.8-18.8-39.2-39-46l-0.4-0.1c-30.4-9.5-55.3-19.2-79.4-30.2
l4.3,1.8c-7-3.5-11.7-10.5-11.9-18.6v0c-0.2-1.2-0.3-2.6-0.3-4.1c0-14,10.6-25.4,24.2-26.8h0.1c7-1.7,15.1-2.6,23.4-2.6
c7.2,0,14.3,0.7,21.2,2.1l-0.7-0.1c11.4,2.2,22.3,6.6,33.4,9.8c4.4,1.4,9.2,3.3,12.2-2.5c1.5-3.2,2.4-7,2.4-11
c0-9.9-5.5-18.6-13.7-23l-0.1-0.1c-16.5-7.9-35.8-12.4-56.2-12.4c-18.2,0-35.5,3.7-51.3,10.3l0.9-0.3c-24.2,9.6-40.9,32.8-40.9,59.9
c0,26,15.4,48.4,37.6,58.5l0.4,0.2c12,5.8,25.1,9.2,37.8,13.9c12.7,4.7,25.3,9.1,37.7,14.2c8.5,4.7,14.2,13.7,14.2,24
c0,9.5-4.9,17.9-12.3,22.8l-0.1,0.1c-43.3,24-101.3-3.8-108.1-5.9c-5.1-1.7-8.3,0.3-9.8,5.8c-1.3,3-2,6.4-2,10.1
c0,12.3,8.5,22.6,20,25.4l0.2,0c17.7,7.1,38.2,11.2,59.7,11.2c18.2,0,35.7-3,52.1-8.4l-1.2,0.3L777.1,391.9z M522.6,646.1
c83.3-8.9,158.9-35.1,225.4-74.8l-2.5,1.4c12.5-7.3,24.2-16,36.1-24.5c11.4-8,5-28.7-17.7-19.1c-78.1,34.3-168.9,56.2-264.2,60.7
l-1.7,0.1c-10,0.5-21.7,0.8-33.5,0.8c-38.4,0-76.1-2.9-113-8.6l4.1,0.5c-103.9-15-197.5-48.6-281-97.6l3.6,2c-2.9-2-6.3-3.6-9.9-4.6
l-0.3-0.1c-7.7-1.7-12,7.3-3.8,15.1C166.8,592,304.1,650,455,650h0.5h0C477.9,648.7,500.4,648.6,522.6,646.1L522.6,646.1z
 M795.5,502.1c23.1,1.3,30.3,9.2,24.5,31.5c-5.9,23.1-14.5,45.6-21.7,68.4c-1.4,4.5-6.6,9.8-1.3,13.8c5.8,4.1,10.2-1.6,14.1-5.1
c15.2-14.4,27.1-32.1,34.8-51.8l0.3-1c8.6-18.8,13.6-40.8,13.6-64c0-0.5,0-1,0-1.6v0.1c-0.3-9.7-2.8-13.8-12.2-16.4
c-7.5-2.3-16.2-4.1-25.2-4.9l-0.5,0c-6.1-0.8-13.1-1.2-20.3-1.2c-27.9,0-54.2,6.6-77.6,18.2l1-0.5c-5.2,2.8-9.6,5.9-13.7,9.3
l0.1-0.1c-1.7,1.4-5,8.3,3.8,9.5c0.8,0.1,1.8,0.1,2.7,0.1c2.3,0,4.6-0.2,6.7-0.6l-0.2,0c17.5-2.5,37.8-3.9,58.4-3.9
c4.5,0,8.9,0.1,13.3,0.2L795.5,502.1L795.5,502.1z"/>
        </svg>
    )
}
export default AwsIcon
