import { Route, Routes, BrowserRouter } from "react-router-dom"

import { HomePage, NotFoundPage } from "../components"

const Router = () => {
    return (
        <main>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route path="/*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </main>
    )
}

export default Router