import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import s from './not-found.module.scss'
import { Button } from "components"

function NotFound() {
    const navigate = useNavigate()

    const navigateHome = () => {
        navigate('/')
    }

    return (
        <>
            <Helmet>
                <title>Ryan Murphy | Not Found</title>
            </Helmet>
            <div className={s.container}>
                <h1 className='bold'>404</h1>
                <h2 className='h6'>Page Not Found</h2>

                <Button
                    styleType='button'
                    functionType='click'
                    text='Go Home'
                    icon='home-filled'
                    color='filled_green'
                    onClick={navigateHome}
                />

            </div>
        </>

    );
}

export default NotFound;