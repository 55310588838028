import React from "react"

const TypeScriptIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 28.7 28.7" className={className}>
            <path d="M2.3,14.3v12h24v-24h-24V14.3z M21.7,13.4c0.6,0.2,1.1,0.4,1.5,0.9c0.2,0.2,0.5,0.7,0.6,0.8c0,0-1,0.7-1.7,1.1
	c0,0-0.1-0.1-0.2-0.2c-0.3-0.4-0.6-0.6-1.1-0.7c-0.7-0.1-1.2,0.3-1.2,1c0,0.2,0,0.3,0.1,0.4c0.2,0.3,0.5,0.5,1.4,0.9
	c1.7,0.7,2.5,1.2,2.9,1.9c0.5,0.8,0.6,2,0.3,2.9c-0.4,1-1.3,1.7-2.7,1.9c-0.4,0.1-1.4,0.1-1.8,0c-1-0.2-1.9-0.6-2.4-1.3
	c-0.2-0.2-0.7-0.9-0.6-0.9c0.1-0.1,0.1-0.1,0.2-0.1c0.1-0.1,0.5-0.3,0.9-0.5l0.7-0.4l0.1,0.2c0.2,0.3,0.6,0.7,0.9,0.9
	c0.8,0.4,1.8,0.3,2.3-0.1c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3,0-0.4-0.2-0.6c-0.2-0.3-0.6-0.5-1.6-1c-1.2-0.5-1.8-0.9-2.3-1.4
	c-0.3-0.3-0.5-0.8-0.7-1.2c-0.1-0.3-0.1-1.2,0-1.5c0.3-1.2,1.2-2,2.5-2.3C20.3,13.3,21.3,13.3,21.7,13.4z M16,14.4l0,1h-3.1v8.9
	h-2.2v-8.9H7.6v-1c0-0.5,0-1,0-1c0,0,1.9,0,4.2,0l4.2,0L16,14.4z"/>
        </svg>
    )
}
export default TypeScriptIcon
