

const useDownloadFile = () => {

    function onDownload(filepath, fileName) {
        const link = document.createElement('a')
        link.href = filepath
        link.download = fileName
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return onDownload
}

export default useDownloadFile