
export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

export function throttle(func, delay) {
    let timeoutId
    let lastExecuted = 0

    return function (...args) {
        const now = Date.now()

        if (now - lastExecuted >= delay) {
            func.apply(this, args)
            lastExecuted = now
            return
        }

        clearTimeout(timeoutId)

        timeoutId = setTimeout(() => {
            func.apply(this, args)
            lastExecuted = Date.now()
        }, delay - (now - lastExecuted))
    }
}
