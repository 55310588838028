import React from 'react';

import s from './main.module.scss'
import { Footer, Header } from 'components'

function MainLayout({ children }) {
    return (
        <div>
            <Header />
            <div className={s.container}>
                <div className={s.wrapper}>
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MainLayout;
