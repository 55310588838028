import React from "react"
// https://iconduck.com/icons/58486/logo-nodejs

const MongoDBIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={className}
            viewBox="0 0 1024 1032.1" >
            <path d="M506.3,741.9c0,0,0-331.5,11-331.5c8.5,0,19.6,427.7,19.6,427.7C521.6,836.3,506.3,767.7,506.3,741.9L506.3,741.9z
M719.7,414.2c-29.4-128.5-93.8-239-182.6-324.3l-0.2-0.2c-10.8-16.5-20.7-35.5-28.6-55.4l-0.8-2.2c0,0.7,0,1.4,0,2.2
c0,18.1-8.1,34.2-20.9,45.1l-0.1,0.1c-114.4,97.1-187.1,240.2-189.5,400.3l0,0.4c0,2,0,4.3,0,6.6c0,158.6,76.3,299.4,194.2,387.7
l1.3,0.9l2.8,2c5.1,38.1,9.5,76.2,13.2,114.4h19.2c5.3-47.7,12.5-89.5,21.9-130.4l-1.5,7.7c12.7-8.8,23.8-17.9,34.1-27.8l-0.1,0.1
c89.6-83.1,145.6-201.5,145.6-332.9c0-1.9,0-3.9,0-5.8l0,0.3c-0.1-31.4-3-62.1-8.4-91.9L719.7,414.2L719.7,414.2z"/>
        </svg>

    )
}
export default MongoDBIcon
