import {
    useLastScrollY,
    useScrollX,
    useScrollY,
    useLastScrollX,
    useChangeScrollY,
    useChangeScrollX
} from "stores";

const useScroll = () => {
    const y = useScrollY()
    const x = useScrollX()
    const lastY = useLastScrollY()
    const lastX = useLastScrollX()
    const changeY = useChangeScrollY()
    const changeX = useChangeScrollX()

    return {
        y,
        x,
        lastX,
        lastY,
        changeY,
        changeX
    }
}

export default useScroll