import React, { useRef, useEffect, useState, useMemo } from "react"
import gsap from "gsap"
import s from './skills.module.scss'

import { SectionLabel } from 'components'
import { useIntersectionObserver } from "hooks"

import GraphQLIcon from "assets/icons/graphql"
import MongoDBIcon from "assets/icons/mongoDB"
import NodeIcon from "assets/icons/node"
import ExpressIcon from "assets/icons/express"
import NextIcon from "assets/icons/next"
import JavaScriptIcon from "assets/icons/javascript"
import ReactIcon from "assets/icons/react"
import VueIcon from "assets/icons/vue"
import CSSIcon from "assets/icons/css"
import NuxtIcon from "assets/icons/nuxt"
import TypeScriptIcon from "assets/icons/typescript"
import HtmlIcon from "assets/icons/html"
import IllustratorIcon from "assets/icons/illustrator"
import PhotoshopIcon from "assets/icons/photoshop"
import GitIcon from "assets/icons/git"
import PostmanIcon from "assets/icons/postman"
import XcodeIcon from "assets/icons/xcode"
import AwsIcon from "assets/icons/aws"
import AndroidStudioIcon from "assets/icons/android-studio"
import CloudfrontIcon from "assets/icons/cloudfront"
import LambdaIcon from "assets/icons/lambda"
import S3Icon from "assets/icons/s3"
import DynamoDBIcon from "assets/icons/dynamo"

const Skills = () => {
    const ref = useRef()
    const animation = useRef(null)
    const [activeIndex, setIndex] = useState(null)
    const entry = useIntersectionObserver(ref, {
        threshold: .25,
        root: null,
        rootMargin: "0% 0px -15% 0px",
    });

    const animateIn = () => {
        if (animation.current) {
            animation.current.kill()
        }

        setIndex(0)
        animation.current = gsap.to(ref.current, {
            opacity: 1,
            duration: 1
        })
    }

    const animateOut = () => {
        if (animation.current) {
            animation.current.kill()
        }

        animation.current = gsap.to(ref.current, {
            opacity: 0,
            duration: 1,
            onComplete: () => {
                setIndex(null)
            }
        })
    }

    useEffect(() => {
        if (entry?.isIntersecting) {
            animateIn()
        }
        else {
            animateOut()
        }

    }, [entry?.isIntersecting])


    const skills = useMemo(() => {
        return [
            {
                name: 'Frontend',
                message: 'Specializing in crafting captivating user experiences, I create seamless and visually appealing user interfaces that leave a lasting impression on users.',
                technologies: [

                    {
                        name: 'TypeScript',
                        iconRef: TypeScriptIcon
                    },
                    {
                        name: 'React',
                        iconRef: ReactIcon
                    },
                    {
                        name: 'Vue',
                        iconRef: VueIcon
                    },
                    {
                        name: 'Next.js',
                        iconRef: NextIcon
                    },
                    {
                        name: 'Nuxt.js',
                        iconRef: NuxtIcon
                    },
                    {
                        name: 'JavaScript',
                        iconRef: JavaScriptIcon
                    },
                    {
                        name: 'CSS',
                        iconRef: CSSIcon
                    },
                    {
                        name: 'HTML',
                        iconRef: HtmlIcon
                    },
                ]
            },
            {
                name: 'Backend',
                message: 'Creating efficient and scalable server-side solutions to ensure seamless application functionality, performance, and security.',
                technologies: [
                    {
                        name: 'Node.js',
                        iconRef: NodeIcon
                    },
                    {
                        name: 'GraphQL',
                        iconRef: GraphQLIcon
                    },
                    {
                        name: 'Express.js',
                        iconRef: ExpressIcon
                    },
                    {
                        name: 'MongoDB',
                        iconRef: MongoDBIcon
                    },
                    {
                        name: 'DynamoDB',
                        iconRef: DynamoDBIcon
                    },
                    {
                        name: 'Lambda',
                        iconRef: LambdaIcon
                    },
                    {
                        name: 'Cloudfront',
                        iconRef: CloudfrontIcon
                    },
                    {
                        name: 'S3',
                        iconRef: S3Icon
                    },
                ]
            },
            {
                name: 'Tools',
                message: 'Utilizing essential tools, I streamline development processes to maximize efficiency and deliver exceptional results.',
                technologies: [
                    {
                        name: 'AWS',
                        iconRef: AwsIcon
                    },
                    {
                        name: 'Xcode',
                        iconRef: XcodeIcon
                    },
                    {
                        name: 'Android Studio',
                        iconRef: AndroidStudioIcon
                    },

                    {
                        name: 'Postman',
                        iconRef: PostmanIcon
                    },
                    {
                        name: 'Git',
                        iconRef: GitIcon
                    },
                    {
                        name: 'Illustrator',
                        iconRef: IllustratorIcon
                    },
                    {
                        name: 'Photoshop',
                        iconRef: PhotoshopIcon
                    }
                ]
            }
        ]
    }, [])

    return (
        <section className={s.container}>
            <SectionLabel id='skills-section' text='Skills & Tools' />

            <div ref={ref} className={s.content_container}>
                <div className={s.headline_wrapper}>
                    {skills.map((item, index) => {
                        return (
                            <div
                                key={`${item.name}-${index}`}
                                className={`${s.headline} ${activeIndex === index && s.active}`}
                                onClick={() => setIndex(index)}
                            >
                                <h2>{item.name}</h2>
                                <p>{item.message}</p>
                            </div>
                        )
                    })}
                </div>

                <div className={s.card_wrapper}>
                    <Cards
                        items={skills[activeIndex]?.technologies}
                    />
                </div>

            </div>
        </section>
    )
}

export default Skills


const Cards = ({ items }) => {
    const technologyCardRefs = useRef([])

    useEffect(() => {
        if (items == null) return
        const cards = technologyCardRefs.current

        gsap.to(cards, {
            opacity: 1,
            duration: .3,
            ease: "power1.inOut",
            stagger: {
                amount: .35,
                grid: 'auto'
            }
        })

    }, [items])

    return items?.map((item, index) => {
        return (
            <div className={s.card}>
                <div
                    ref={(element) => (technologyCardRefs.current[index] = element)}
                    key={`${item.name}-${index}`}
                    style={{ opacity: 0 }}
                    className={s.inner}>
                    <item.iconRef className={s.icon} />
                </div>
                <p>{item.name}</p>
            </div>
        )
    })
}