import React from "react"

const AndroidStudioIcon = ({ className }) => {
    return (
        <svg width="800px" height="800px" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M12 0C6.0697 0 1.254 4.8158 1.254 10.746c0 3.29 1.4819 6.237 3.8144 8.209l4.5097-7.7987-.3926-.3907c-.115-.1151-.173-.2124-.1757-.3027a.2386.2386 0 010-.0293H9V7.875c0-.6155.5095-1.123 1.125-1.123h.6348V4.7383H13.25V6.752h.625c.6253 0 1.125.5075 1.1348 1.1328v2.539H15v.0098h-.002c-.0032.0868-.0511.1996-.1738.3223l-.0097.0097-.3907.3907 1.9336 3.3417c.8695-1.016 1.3965-2.3255 1.3965-3.752h2.004c0 2.1788-.9096 4.1538-2.3556 5.5606l1.5293 2.6504c2.333-1.9721 3.8145-4.9207 3.8145-8.211C22.7559 4.806 17.94 0 12 0zm6.9316 18.957a10.7911 10.7911 0 01-1.6523 1.1504l1.5195 2.627a.5687.5687 0 00.1973.205L20.5 23.9649c.0586.0391.127.0391.1855.0098.0587-.039.088-.0976.088-.166l-.1563-1.8066c-.0098-.0977-.039-.1856-.0879-.2735l-1.5977-2.7715zm-1.6523 1.1504l-1.498-2.5898a7.6932 7.6932 0 01-3.7715.9863 7.6932 7.6932 0 01-3.7715-.9863l-1.5 2.5976c1.556.8767 3.3504 1.379 5.2617 1.379 1.9182 0 3.7195-.5042 5.2793-1.3868zm-10.541.0078a10.788 10.788 0 01-1.67-1.1601l-1.5976 2.7636c-.0488.088-.0781.1758-.0879.2735l-.1367 1.8164c-.0098.0684.0293.127.0879.166.0586.039.127.0293.1855-.0098l1.504-1.0156c.0781-.0586.1464-.1269.1952-.205l1.5196-2.629zM12 7.7285v.0195c-.8288 0-1.4915.6622-1.4941 1.5.0026.8283.675 1.498 1.4941 1.498h.0098c.819-.0097 1.4914-.6699 1.4941-1.498-.0026-.8281-.675-1.4902-1.4941-1.5L12 7.7285zm-.9473 4.9043l-1.8183 3.1465A5.7067 5.7067 0 0012 16.4922c1.0063 0 1.9547-.2537 2.7559-.7031l-1.8145-3.1504-.375.375c-.1465.1563-.3515.2343-.5566.2343h-.0196c-.1954 0-.4003-.078-.5566-.2343l-.3809-.3809Z" />
        </svg>
    )
}
export default AndroidStudioIcon
