import React, { useEffect, useRef, useState } from "react";
import gsap from 'gsap'
import s from './header.module.scss'

import { Button } from "components";
import { scrollToSection, scrollToTop } from "utility";
import { useScroll, useDisableScroll } from 'hooks'

const Header = () => {
  const animatedValue = useRef(0);
  const headerRef = useRef()
  const menuRef = useRef()
  const burgerRef = useRef()
  const logoRef = useRef()
  const [menuActive, toggleMenuActive] = useState(false)
  const { y, changeY } = useScroll()
  const { disableScroll, enableScroll } = useDisableScroll()

  const clamp = 350
  const duration = 0.3
  const computedStyle = getComputedStyle(document.documentElement)
  const primaryGreen = computedStyle.getPropertyValue('--green-primary')
  const primary = computedStyle.getPropertyValue('--primary')
  const logoMinScale = computedStyle.getPropertyValue('--min-header-logo-scale')
  const logoMaxScale = computedStyle.getPropertyValue('--max-header-logo-scale')
  const headerMaxHeight = parseFloat(computedStyle.getPropertyValue('--max-header-height'))
  const headerMinHeight = parseFloat(computedStyle.getPropertyValue('--min-header-height'))
  const metaTag = document.querySelector('meta[name="theme-color"]')

  useEffect(() => {
    if (!logoRef.current || !headerRef.current) return

    animatedValue.current = Math.min(Math.max(animatedValue.current + changeY, 0), clamp)
    const normalizedScroll = Math.min(animatedValue.current / clamp, 1)
    const headerHeight = headerMaxHeight - normalizedScroll * (headerMaxHeight - headerMinHeight)
    const logoScale = logoMaxScale - normalizedScroll * (logoMaxScale - logoMinScale)

    logoRef.current.style.transform = `scale(${logoScale})`;
    burgerRef.current.style.transform = `scale(${logoScale})`;
    headerRef.current.style.height = `${headerHeight}rem`

  }, [y, changeY, headerMaxHeight, headerMinHeight, logoMinScale, logoMaxScale])

  const toggleNavMenu = async () => {
    if (menuActive) {
      await closeMenu()
      return
    }

    await openMenu()
  }

  const navToSection = async (sectionId) => {
    if (menuActive) {
      await closeMenu()
    }

    scrollToSection(sectionId)
  }

  const navHome = async () => {
    if (menuActive) {
      await closeMenu()
    }

    scrollToTop()
  }

  const openMenu = () => {
    return new Promise((resolve) => {
      if (!menuRef.current) return

      const tl = gsap.timeline({
        duration,
        onComplete: () => {
          animatedValue.current = 0
          resolve()
        }
      })

      disableScroll()
      toggleMenuActive(true)
      gsap.set(menuRef.current, { display: "flex" })
      metaTag.setAttribute("content", primary)
      tl
        .to(menuRef.current, {
          opacity: 1,
        }, '<')
        .to(headerRef.current, {
          height: `${headerMaxHeight}rem`,
        }, '<')
        .to(logoRef.current, {
          scale: logoMaxScale,
        }, '<')
        .to(burgerRef.current, {
          scale: logoMaxScale,
        }, '<')
    })
  }

  const closeMenu = () => {
    return new Promise((resolve) => {
      if (!menuRef.current) return

      const tl = gsap.timeline({
        duration,
        onComplete: () => {
          gsap.set(menuRef.current, { display: "none" })
          enableScroll()
          resolve()
        }
      })

      metaTag.setAttribute("content", primaryGreen)
      toggleMenuActive(false)
      tl
        .to(menuRef.current, {
          opacity: 0,
          duration: duration * .75,
        }, '<')
    })
  }



  function navToAbout() {
    navToSection('about-section')
  }

  function navToSkills() {
    navToSection('skills-section')
  }

  // function navToProjects() {
  //   navToSection('projects-section')
  // }

  function navToContact() {
    navToSection('contact-section')
  }

  return (
    <header ref={headerRef} className={s.container}>
      <div className={s.inner}>
        <button className={`${s.logo}`} onClick={navHome}>
          <img ref={logoRef} src={'/favicon/apple-touch-icon.png'} alt='RM' />
        </button>

        <nav>
          <div className={s.desktop_nav}>

            <Button
              bold
              styleType='link'
              functionType='click'
              text='About'
              onClick={navToAbout}
            />

            <Button
              bold
              styleType='link'
              functionType='click'
              text='Skills & Tools'
              onClick={navToSkills}
            />

            {/* <Button
              bold
              styleType='link'
              functionType='click'
              text='Projects'
              onClick={navToProjects}
            /> */}

            <Button
              bold
              styleType='link'
              functionType='click'
              text='Contact'
              onClick={navToContact}
            />
          </div>

          <div className={s.mobile_nav}>
            <button
              ref={burgerRef}
              className={`${s.burger} ${menuActive && s.active}`}
              onClick={toggleNavMenu}>
              <span className={s.top}></span>
              <span className={s.middle}></span>
              <span className={s.bottom}></span>
            </button>

            <div ref={menuRef} className={s.menu}>
              <div className={s.inner}>

                <div className={s.nav_links}>
                  <div className={s.mobileNavLink}>
                    <Button
                      bold
                      styleType='link'
                      functionType='click'
                      text='About'
                      color='black'
                      onClick={navToAbout}
                    />
                  </div>

                  <div className={s.mobileNavLink}>
                    <Button
                      bold
                      styleType='link'
                      functionType='click'
                      text='Skills & Tools'
                      color='black'
                      onClick={navToSkills}
                    />
                  </div>

                  {/* <div className={s.mobileNavLink}>
                    <Button
                      bold
                      styleType='link'
                      functionType='click'
                      text='Projects'
                      color='black'
                      onClick={navToProjects}
                    />
                  </div> */}

                  <div className={s.mobileNavLink}>
                    <Button
                      bold
                      styleType='link'
                      functionType='click'
                      text='Contact'
                      color='black'
                      onClick={navToContact}
                    />
                  </div>
                </div>

                <div className={`${s.social_links}`}>
                  <Button
                    styleType='button'
                    functionType='link-external'
                    icon='linkedin-filled'
                    color='filled_black'
                    destination='https://www.linkedin.com/in/ryanmurphy-dev'
                  />

                  <Button
                    styleType='button'
                    functionType='link-external'
                    icon='github-filled'
                    color='filled_black'
                    destination='https://github.com/murph406'
                  />

                  <Button
                    styleType='button'
                    functionType='link-external'
                    icon='twitter-filled'
                    color='filled_black'
                    destination='https://github.com/murph406'
                  />
                </div>

              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header