import React, { useRef } from "react";
import { useGLTF, OrbitControls, Stage, PerspectiveCamera } from "@react-three/drei";
import { isMobile } from 'react-device-detect'

import ModelRef from 'assets/models/model-1.glb'

export function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(ModelRef);
  const shadows = { value: true }
  return (
    <>
      <Stage
        controls={group}
        adjustCamera={false}
        shadows={shadows}>
        <group ref={group} {...props} dispose={null} >
          <mesh
            castShadow
            receiveShadow
            scale={isMobile ? 1.25 : 1}
            geometry={nodes.Object_4.geometry}
            material={materials["Material.001"]}
          >
          </mesh>
        </group>

        {/* 
        // Option 2
        <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          scale={.5}
          geometry={nodes.Abstract_Sphere_Ball_v2_003.geometry}
          material={materials.VRayMtl1SG}
        />
      </group> */}
      </Stage >
      <OrbitControls ref={group} autoRotate={true} enableZoom={false} enablePan={false} />
      <ambientLight intensity={1.25} />
      <PerspectiveCamera
        makeDefault={false}
        far={10000}
        fov={45}
        position={[0.779, 15.148, 14.776]}
        rotation={[-0.442, 0.047, 0.022]}
      />
      <PerspectiveCamera
        makeDefault={false}
        far={10000}
        fov={29.283}
        position={[-12.236, 11.263, 20.77]}
        rotation={[-0.159, -0.532, -0.081]}
      />
      <PerspectiveCamera
        makeDefault={false}
        far={10000}
        fov={29.283}
        position={[13.952, 23.566, 15.085]}
        rotation={[-0.799, 0.573, 0.509]}
      />
      <PerspectiveCamera
        makeDefault={false}
        far={10000}
        fov={29.283}
        position={[-14.376, -0.449, 16.672]}
        rotation={[0.474, -0.651, 0.302]}
      />
      <PerspectiveCamera
        makeDefault={false}
        far={10000}
        fov={29.283}
        position={[15.759, 14.466, 35.373]}
        rotation={[-0.177, 0.406, 0.07]}
      />
    </>
  );
}

useGLTF.preload(ModelRef);
export default Model


