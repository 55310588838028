import React from "react"

const PostmanIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 869 869" className={className}>
            <path d="M485.4,37.8C266.3,9.8,65.9,164.5,37.8,383.6c-28,219.1,126.7,419.5,345.8,447.6c219.1,28,419.5-126.7,447.5-345.8
C859.3,266.3,704.6,65.9,485.4,37.8L485.4,37.8z M567.7,287.4c-7.4,0-14.5,3-19.7,8.3L399.6,444l-10.3-10.2L368,412.4
C514.2,266.5,540.7,265.1,567.7,287.4L567.7,287.4z M405.8,449.3l147.9-148c8.2-8.6,21.9-8.5,30.1,0.1c8.1,8.7,7.3,22.4-1.8,29.9
L425.3,468.9L405.8,449.3z M416.7,472.5l-36.7,7.9c-0.8,0.1-1.8-0.3-2.2-1.2c-0.4-0.8-0.3-1.7,0.4-2.4l21.5-21.5L416.7,472.5z
 M323.2,457.2l39.2-39.1l29.3,29.3l-66,14.2c-1.2,0.3-2.3-0.2-2.9-1.3C322.2,459.2,322.4,458,323.2,457.2z M201.7,659.1
c-1.4-0.1-2.5-1.4-2.4-2.7c0.1-0.6,0.4-1.2,0.8-1.6h0.1l31.5-31.5l40.8,40.8L201.7,659.1z M282.6,617.2c-3.1,1.7-4.7,5.2-3.9,8.6
l6.8,28.8c1.1,4.2-4,7-7,3.9h-0.1l-40.9-40.9l125.4-125.3l60.7-13.1l29,29.2C410.7,545.1,353.5,581.7,282.6,617.2L282.6,617.2z
 M458.5,503l-28-28l156.6-137.5c1.4-1.3,2.7-2.7,3.9-4.2C586.2,378.1,523.5,441.4,458.5,503z M580.5,287.6h-0.1
c-54.6-54.8,22.5-139.1,81.9-89.6l-53.6,53.8c-1.6,1.5-1.6,4.2,0,5.7l41.5,41.6C626.9,310.7,598.9,306,580.5,287.6L580.5,287.6z
 M666.3,287.6c-2.7,2.7-5.7,5.2-9,7.3l-40.2-40.2l51-51.2C690.3,227.6,689.4,264.6,666.3,287.6L666.3,287.6z M663,236.2
c-1.7,1.2-2.3,3.2-1.8,5.2c2.5,4.9,1.8,10.8-1.7,15c-1.7,2.1-1.3,5,0.8,6.6c0.8,0.5,1.7,0.9,2.7,0.9c1.4,0,2.8-0.5,3.5-1.6
c5.9-7,7-16.9,3-25.1C668,235.2,665.1,234.6,663,236.2z"/>
        </svg>
    )
}
export default PostmanIcon
