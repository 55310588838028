import React from "react"
// icons from https://remixicon.com/

const FileTextLineIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" view Box="0 0 24 24" className={ className }>
            <path d="M21 8V20.9932C21 21.5501 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.4487 2 4.00221 2H14.9968L21 8ZM19 9H14V4H5V20H19V9ZM8 7H11V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z"></path>
        </svg>
    )
}
export default FileTextLineIcon