import React, { useRef, useEffect, useCallback } from "react"
import gsap from "gsap"
import { isMobile } from 'react-device-detect'

import s from './section-label.module.scss'
import { useIntersectionObserver, useScroll } from "hooks"

const SectionLabel = ({ text, id, scrollTimer = 15000 }) => {
    const duration = 0.3
    const ref = useRef()
    const labelRef = useRef()
    const mouseRef = useRef()
    const timer = useRef()
    const { y } = useScroll()
    const entry = useIntersectionObserver(ref, {
        threshold: 1.0,
        root: null,
        rootMargin: isMobile ? '0px 0px -15% 0px' : '0px 0px -10% 0px',
    })

    const animateLabelIn = () => {
        const tl = gsap.timeline({
            duration,
        })

        tl
            .to(labelRef.current, {
                opacity: 1
            }, '<')
            .to(mouseRef.current, {
                opacity: 0
            }, '<')
    }

    const animateOut = () => {
        const tl = gsap.timeline({
            duration,
        })

        tl
            .to(labelRef.current, {
                opacity: 0
            }, '<')
            .to(mouseRef.current, {
                opacity: 0
            }, '<')
    }

    const animateMouseIn = () => {
        const tl = gsap.timeline({
            duration: duration * 3,
        })

        tl
            .to(labelRef.current, {
                opacity: 0
            }, '<')
            .to(mouseRef.current, {
                opacity: 1
            }, '<')
    }

    const setTimeoutInterval = useCallback(() => {
        timer.current = setTimeout(() => {
            if (entry?.isIntersecting === false) {
                animateMouseIn()
            }
        }, scrollTimer)
    }, [scrollTimer, entry])

    useEffect(() => {
        if (entry?.isIntersecting) {
            clearTimeout(timer.current)
            animateLabelIn()
        }
        else {
            animateOut()
            setTimeoutInterval()
        }

    }, [entry?.isIntersecting, setTimeoutInterval, entry])

    useEffect(() => {
        if (entry?.isIntersecting === false) {
            clearTimeout(timer.current)
            setTimeoutInterval()
            animateOut()
        }
    }, [y, setTimeoutInterval, entry?.isIntersecting])

    useEffect(() => {
        const clear = () => {
            if (entry?.isIntersecting === false) {
                clearTimeout(timer.current)
                setTimeoutInterval()
                animateOut()
            }
        }

        document.body.addEventListener('click', clear)

        return () => {
            document.body.removeEventListener('click', clear)
        }

    }, [setTimeoutInterval, entry?.isIntersecting])

    return (
        <div ref={ref} id={id} className={s.container}>

            <div ref={labelRef} className={s.labelWrapper}>
                <div className={s.border} />
                <p>{text}</p>
                <div className={s.border} />
            </div>

            <div ref={mouseRef} className={s.mouseScroll}>
                <div className={s.mouse}>
                    <div className={s.mouseIn}></div>
                </div>
                <p>Scroll For More</p>
            </div>

        </div>
    )
}

export default SectionLabel