import React from 'react'
import { Helmet } from "react-helmet-async"

import { MainLayout, Hero, About, Skills, Contact } from 'components'

function Home() {

  return (
    <>
      <Helmet>
        <title>Ryan Murphy | Home</title>
      </Helmet>

      <MainLayout>
        <Hero />
        <About/>
        <Skills/>
        {/* <Projects/> */}
        <Contact/>
      </MainLayout>
    </>
  );
}

export default Home;
