import React from "react"

const ExpressIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className}
            x="0px" y="0px" viewBox="0 0 1421.2 824.5" >
            <path d="M1377.3,779c-48.5,12.3-78.5,0.5-105.4-39.9l-191.4-264.8l-27.7-36.7L829.2,740c-25.5,36.4-52.3,52.2-100,39.3l286.2-384.3
	L749,48c45.8-8.9,77.5-4.4,105.6,36.7l198.5,268.1l200-266.7c25.6-36.4,53.2-50.2,99.2-36.8l-103.3,137l-140,182.3
	c-16.7,20.8-14.4,35.1,1,55.2L1377.3,779z M44.3,388.7l23.4-115.2c63.8-227.9,325-322.6,505.2-181.8
	c105.3,82.8,131.5,200,126.2,331.2H105.6C96.7,659.2,266.8,801.8,483.5,729c76-25.5,120.8-85.1,143.2-159.6
	c11.4-37.3,30.2-43.2,65.3-32.5c-17.9,93.2-58.3,171-143.8,219.7c-127.6,72.9-309.8,49.3-405.6-52c-57.1-58.8-80.7-133.3-91.2-213
	c-1.7-13.2-5-25.7-7.5-38.3C44.2,431.8,44.3,410.3,44.3,388.7L44.3,388.7z M106.7,372.8H643C639.5,202,533.2,80.7,387.8,79.6
	C228.2,78.4,113.6,196.8,106.7,372.8L106.7,372.8L106.7,372.8z"/>
        </svg>
    )
}
export default ExpressIcon
