import React from "react"
// icons from https://remixicon.com/

const LinkedInFilledIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
            <path d="M18.6,18.9h-2.8v-4.3c0-1,0-2.4-1.4-2.4c-1.4,0-1.7,1.1-1.7,2.3v4.4H9.9V10h2.7v1.2h0c0.4-0.7,1.3-1.4,2.6-1.4
	c2.8,0,3.3,1.8,3.3,4.2L18.6,18.9L18.6,18.9z M6.8,8.8c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
	C8.4,8.1,7.7,8.8,6.8,8.8z M8.2,18.9H5.4V10h2.8V18.9z M20,3H4C3.3,3,2.7,3.6,2.7,4.3v16c0,0.7,0.6,1.3,1.4,1.3h15.9
	c0.8,0,1.4-0.6,1.4-1.3v-16C21.3,3.6,20.7,3,20,3L20,3z"/>
        </svg>

    )
}
export default LinkedInFilledIcon
