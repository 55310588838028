import { useState, useEffect } from "react"

const useIntersectionObserver = (ref, options = {}) => {
    const { threshold = 1, root = null, rootMargin = "0%" } = options
    const [entry, setEntry] = useState(null)

    useEffect(() => {
        const node = ref?.current;
        if (!node || typeof IntersectionObserver !== "function") return

        const observer = new IntersectionObserver(
            ([entry]) => {
                setEntry(entry)
            },
            { threshold, root, rootMargin }
        )

        observer.observe(node)

        return () => {
            setEntry(null)
            observer.disconnect()
        }

    }, [threshold, root, rootMargin, ref])

    return entry
}

export default useIntersectionObserver