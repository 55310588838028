import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import s from './about.module.scss'

import Me from 'assets/images/me.jpg'

import { SectionLabel, Button } from 'components'
import { useDownloadFile, useIntersectionObserver } from "hooks";

const About = () => {
    const ref = useRef()
    const downloadFile = useDownloadFile()
    const entry = useIntersectionObserver(ref, {
        threshold: 0,
        root: null,
        rootMargin: "-15%",
    })

    const downloadCV = () => {
        const filepath = '/resume.pdf'
        const filename = 'ryan-murphys-resume.pdf'

        downloadFile(filepath, filename)
    }

    const animateIn = () => {
        gsap.to(ref.current, {
            opacity: 1,
            duration: 1
        })
    }

    const animateOut = () => {
        gsap.to(ref.current, {
            opacity: 0,
            duration: 1
        })
    }

    useEffect(() => {

        if (entry?.isIntersecting) {
            animateIn()
        }
        else {
            animateOut()
        }

    }, [entry?.isIntersecting])

    return (
        <section className={s.container}>
            <SectionLabel id='about-section' text='About' scrollTimer={7500} />

            <div ref={ref} className={s.content_container} >
                <div className={s.section}>
                    <img src={Me} alt="Portrait" />
                </div>

                <div className={s.section}>
                    <p className={s.text}>I am a motivated software engineer who is eager to learn and grow in the field. I have experience taking projects from concept to completion and leads in small cross-functional teams throughout the development process. My passion for software is driven by building engaging and user friendly applications that exceed expectations. </p>

                    <div className={s.button_wrapper}>
                        <div>
                            <svg className={s.arrow} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.1 106.72" >
                                <path d="m53.87,11.2c-1.2-4.56-3.9-8.97-8.58-10.55-5.58-1.88-11.55.65-16.47,3.22C17.1,10,7.17,19.17.12,30.34c-.49.77.68,1.4,1.2.7C8.48,21.36,17.45,13.15,28.12,7.49c5.8-3.08,14.53-7.12,19.57-.73,3.76,4.76,3.36,12.5,2.87,18.19-1.1,12.86-6,25.26-10,37.42-3.83,11.63-7.64,23.26-11.45,34.89-2.18-3.57-3.92-7.33-5.25-11.39-.86-2.69-5.1-1.55-4.25,1.17,2.03,6.03,3.99,12.08,5.98,18.13.59,1.79,2.93,2.12,3.96.52.29-.45.57-.89.86-1.34.68-.17,1.27-.65,1.55-1.49.2-.62.41-1.25.61-1.87,2.16-3.36,4.31-6.72,6.47-10.08,1.1-1.71-.56-3.33-2.14-3.23,3.64-11.17,7.29-22.35,10.92-33.53,4.3-13.25,9.78-28.9,6.07-42.96Z" />
                            </svg>
                        </div>

                        <Button
                            styleType='button'
                            functionType='click'
                            text='Download Resume'
                            icon='download-file'
                            color='filled_green'
                            onClick={downloadCV}
                        />
                    </div>
                </div>

            </div>
        </section>
    )
}

export default About