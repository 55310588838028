import React from "react"

const ReactIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 547 527" className={className}>
            <path d="M318.2,262.3c0-35-37.9-57.1-68.1-39.5c-30.2,17.5-30.2,61.6,0,79.1C280.3,319.4,318.2,297.4,318.2,262.3z M422.2,178.2
	c30.1-143.7-36.1-188.8-148.5-84.8C167.9-5.8,92.5,25.4,124.6,177.6C-23.2,222.1-13.5,306,125.8,348.9
	C93.9,489,153.5,540.1,273.7,434.3c116.6,104.6,178.5,55.6,146.7-86C572.3,296.8,558.7,222.2,422.2,178.2z M401.8,172.7
	c-23.8-5.4-46.9-9.3-70.3-11.4c-12.4-18.1-26.7-36.1-43.3-52.9C378.3,22,431.4,49,401.8,172.7z M169.1,290
	c8.9,19,20.6,36.5,29.5,51.1c-14.4-1.5-29.8-3.7-46.3-6.6C157.5,319.7,163.2,304.9,169.1,290z M167.9,238.3
	c-6.6-15.7-12.5-30.9-17.4-45.1c17-3.5,33.7-6.7,48.1-7.8C187.8,202.3,177.6,219.9,167.9,238.3z M180.5,264.7
	c14.7-29.4,30.3-57.4,47.5-82.4c30.6-1.8,60.9-1.6,90.8,0.6c16.8,23.4,32.4,51.3,47.5,81.8C355,288,339,314.8,320.6,343.5
	c-30.8,2.3-62.3,2-94.4,0C210,318.6,194.7,292.4,180.5,264.7z M377.7,290c5.8,14.2,11.2,28.6,16.2,43.3c-15.4,3.2-30.7,5.6-45.7,7.2
	C358.6,324.5,368.3,307.6,377.7,290z M348.3,185.4c16.4,2.2,32.2,5,47.5,8.4c-5.1,15.2-10.9,30-17.4,44.5
	C369,219.9,359,202.3,348.3,185.4z M302,158.9c-18.7-0.7-37.8-0.7-57.1,0c8.5-11.1,17.9-22.8,28.3-34.9
	C283.2,134.9,292.8,146.7,302,158.9z M258.7,108.4c-16.5,16.8-30.9,34.2-43.3,52.3c-23.5,2.1-47.1,6-70.9,11.4
	C116.2,46.5,168.5,22,258.7,108.4z M131.2,329.1C6,291.4,12,232.7,130,198c7.2,21.9,16,44.2,25.9,65.5
	C146.3,285.6,137.8,308.3,131.2,329.1z M146.9,354.3c21.2,5.2,43.8,7.7,67.3,10.2c14.2,20,28.9,38.6,44.5,54.7
	C166.9,507.2,115.7,475.3,146.9,354.3z M273.1,404.2c-10.9-11.3-21-24-30.7-37.9c21.5,0.5,42.2,0.5,61.9,0
	C294.8,379.9,284.4,392.5,273.1,404.2z M288.1,419.2c16.7-18.2,31.5-36.6,44.5-55.3c22.2-1.6,44.6-5,67.3-10.2
	C431.4,482.4,374.3,501.5,288.1,419.2z M415,327.9c-6.4-20.7-14.6-42.2-24-64.3c9.8-21.4,18.1-43,25.3-64.9
	C531.4,233.5,540.7,289.2,415,327.9z"/>
        </svg>

    )
}
export default ReactIcon
