import React from "react"

const GraphQLIcon = ({ className }) => {
    return (
        <svg viewBox="0 0 24 24" className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path d="m14.051 2.751 4.935 2.85a2.144 2.144 0 0 1 3.409.4 2.146 2.146 0 0 1 -1.354 3.151v5.699a2.132 2.132 0 0 1 1.348 3.15 2.143 2.143 0 0 1 -3.453.353l-4.905 2.832a2.14 2.14 0 1 1 -4.171.678c0-.205.029-.41.088-.609l-4.936-2.847a2.14 2.14 0 1 1 -2.052-3.559l.001-5.699a2.141 2.141 0 0 1 -1.357-3.15 2.143 2.143 0 0 1 3.407-.405l4.938-2.85a2.138 2.138 0 0 1 2.051-2.745 2.137 2.137 0 0 1 2.051 2.751zm-.515.877a.793.793 0 0 1 -.058.058l6.461 11.19c.026-.009.056-.016.082-.023v-5.707a2.135 2.135 0 0 1 -1.558-2.588l.019-.072zm-3.015.059-.06-.06-4.946 2.852a2.136 2.136 0 0 1 -1.461 2.645l-.076.021v5.708l.084.023 6.461-11.19zm2.076.507c-.39.112-.803.112-1.192 0l-6.46 11.189c.294.283.502.645.6 1.041h12.911c.097-.398.307-.761.603-1.044zm.986 16.227 4.913-2.838a1.748 1.748 0 0 1 -.038-.142h-12.916l-.021.083 4.939 2.852a2.126 2.126 0 0 1 1.54-.653c.627 0 1.19.269 1.583.698z" />
        </svg>

    )
}
export default GraphQLIcon
